import React from "react"
import Layout from "../components/layout"

export default function Privacy() {
    return (
        <Layout>
            <div className="wrapper">
                <h1>Privacy Policy</h1>
                <p>Last updated: April 28, 2018</p>
                <h2 id="Introduction"><a href="#Introduction" class="headerlink" title="Introduction"></a>Introduction</h2><p>When you use our product and services (the “Service/s”), you accept the terms and conditions (“Terms”) of this Privacy Policy and the processing of your personal data (“Data”).</p>
                <h2 id="Data-controller"><a href="#Data-controller" class="headerlink" title="Data controller"></a>Data controller</h2><p>Responsible for the processing of your Data is:</p>
                <p>Polygram Digital Ltd.<br />Chancery Station House,<br />31-33 High Holborn,<br />London,<br />WC1V 6AX</p>
                <p>E-mail: hello@eatvegly.com</p>
                <h2 id="Data-collected"><a href="#Data-collected" class="headerlink" title="Data collected"></a>Data collected</h2><p>When you use our Services we may collect the following Data, from you: (a) your first and last name, (b) your email address. </p>
                <p>In order to provide you with the best experience, we might also keep track of (c) the features you use, (d) the links you click when using our Services and (e) the country you visit us from. </p>
                <h2 id="Our-use-of-your-Data"><a href="#Our-use-of-your-Data" class="headerlink" title="Our use of your Data"></a>Our use of your Data</h2><p>Your privacy is important to us and we will only process your Data in accordance with the principles of the Data Protection Act 1998.</p>
                <p>We may use your Data for the purposes of improving our product and Services, analyzing usage of the Services, providing customer support, marketing by Polygram (the “Company”, “us”, or “we”) or our selected business partners that help us provide our Services. In case you do not want Polygram to use your Data for the purpose of direct marketing, you may opt-out by clicking the “unsubscribe” link at the bottom of any marketing communication.</p>
                <p>The Company will not share your Data with third parties without your permission, except in the limited circustances provided below. Data collected from you may be shared with selected entities that help us impoving our services by providing audience measurement and analytics services. These entities may use cookies, web beacons, SDKs and other technologies (“Cookies”) to identify your device when you visit our website and use our Services.</p>
                <p>We’ll always treat your personal details with the utmost care and will never share with or sell them to other companies for marketing purposes.</p>
                <p>At times, some of your Data may be transferred and processed in the United States or any other country (which may be outside of the EEA) where the Company maintains facilities. By registering an account with us or using our Services you consent to any such transfer of your Data outside of the EEA.</p>
                <h2 id="Who-we-might-share-your-information-with"><a href="#Who-we-might-share-your-information-with" class="headerlink" title="Who we might share your information with"></a>Who we might share your information with</h2><ul>
                    <li><p>MailChimp and SendGrid<br />We use these services to manage all our email communications with you. We might share with these companies your name and your email address.</p>
                    </li>
                    <li><p>Segment, Google Analytics and Mixpanel<br />We use these services to measure the use of our products and improve our services as well provide support for our users. We might share with this companies the links you click and the pages you visit while using our products. The data we share with these companies are anonymised and we never share with them information that might be used to identify you.</p>
                    </li>
                </ul>
                <h2 id="Payment-information"><a href="#Payment-information" class="headerlink" title="Payment information"></a>Payment information</h2><p>When you register for certain paid Services, you are required to provide certain payment and billing information. For example, You might provide payment information, such as payment card details, which we collect via secure payment processing services such as Stripe or PayPal.</p>
                <h2 id="Data-retention"><a href="#Data-retention" class="headerlink" title="Data retention"></a>Data retention</h2><p>We will retain a record of your personal information. This is done in order to provide you with a high quality and consistent service. We will always retain your personal information in accordance with law and regulation and never retain your information for longer than is necessary.</p>
                <h2 id="Accessing-your-own-Data"><a href="#Accessing-your-own-Data" class="headerlink" title="Accessing your own Data"></a>Accessing your own Data</h2><p>You have the right to ask what Data we hold about you and ask to update or correct any Data we hold about you. If you wish to exercise this right, an application shall be made in writing and signed by you.</p>
                <h2 id="Security"><a href="#Security" class="headerlink" title="Security"></a>Security</h2><p>The security and integrity of your Data is of great importance to us and to protect your Data we have put in place suitable physical, electronic and managerial procedures to safeguard and secure your Data. If a password access is required to access certain parts of the Services, you are responsible for keeping this password confidential. We endeavour to do our best to protect your Data. However, transmission over the internet is not entirely secure and it is done at your own risk. We cannot ensure 100% security of your Data transmitted to us.</p>
                <h2 id="Cookies"><a href="#Cookies" class="headerlink" title="Cookies"></a>Cookies</h2><p>Both us and our partners use cookies and other indentification technologies for the purposes described in this Privacy Policy. All cookies are used in accordance with the current UK Cookie Law. We use cookies and similar technologies for purposes such as: (a) authenticating users, (b) remembering user preferences and settings, (c) analysing the use of our Services and trends, and generally understanding the behaviours and interests of people using our Services. This help us to improve the way our Services and products work, for example, by ensuring that users are finding what they are looking for easily.</p>
                <p>Furthermore, our third party partner may use cookies or similar technologies in order to provide you advertising based upon your browsing activities and interests. If you wish to opt out of interest-based advertising <a href="http://www.youronlinechoices.com/" target="_blank" rel="external">click here</a>. Please note you will continue to receive generic ads.</p>
                <p>You can choose to enable or disable Cookies in your internet browser. By default, most internet browsers accept Cookies but you can change this settings at any time. You can also choose to delete Cookies at any time; however this may affect your access and use of our Services.</p>
                <h2 id="Changes-to-this-policy"><a href="#Changes-to-this-policy" class="headerlink" title="Changes to this policy"></a>Changes to this policy</h2><p>The Company reserves the right to change this Privacy Policy as we may deem necessary from time to time or as may be required by law. Any changes will be immediatley notified to you. Please ensure you review this Privacy Policy and accept its terms and conditions before your first use of our Services.</p>
                <h2 id="Contact"><a href="#Contact" class="headerlink" title="Contact"></a>Contact</h2><p>We will communicate with you by email. Please be aware that if you unsubscribe from our communications you will still be receiving important messages from us but you will not be receiving marketing communications.</p>
                <p>If you have any question regarding our use of your Data and this Privacy Policy, please contact us at hello@eatvegly.com.</p>
            </div>
        </Layout>
    )
}